h1 {
	font-size: $h1-fs;
	line-height: $h1-lh;
	font-weight: 300;
	margin: 0 0 48px; }

h2 {
	font-size: $h2-fs;
	line-height: $h2-lh;
	font-weight: 300;
	margin: 34px 0; }

h3 {
	font-size: $h3-fs;
	line-height: $h3-lh;
	font-weight: 700;
	margin: 45px 0 25px; }

h4 {
	font-size: $h4-fs;
	line-height: $h4-lh;
	font-weight: 300;
	margin: 45px 0 25px; }

h5 {
	font-size: $h5-fs;
	line-height: $h5-lh;
	font-weight: 500;
	margin: 45px 0 25px; }

h6 {
	font-size: $h6-fs;
	line-height: $h6-lh;
	font-weight: 300;
	margin: 45px 0 25px; }

p,
ol li,
ul li {
	font-size: $p-fs;
	line-height: $p-lh;
	margin: 16px 0;

	&+img {
		margin: round($baseGutter*0.2) 0 0; } }

b {
	font-weight: 700; }


img {
	&+h1,
	&+h2,
	&+h3,
	&+h4,
	&+h5,
	&+h6,
	&+p,
	&+ul,
	&+ol {
		margin-top: round($baseGutter*1.3); }

	&+small {
		display: block;
		color: $gray7;
		margin: 7px 0 round($baseGutter*1.5);
		text-align: center; } }

small {
	font-size: $small-fs;
	line-height: $small-lh; }


blockquote {
	font-size: $blockquote-fs;
	line-height: $blockquote-lh;
	margin: 50px 0;
	font-weight: normal;
	padding: 30px 0 50px;
	border-top: 2px solid $baseTxt;
	border-bottom: 2px solid $baseTxt;
	position: relative;

	&:after {
		content: url("../images/lap.png");
		position: absolute;
		right: 0;
		bottom: 20px; }

	p {
		font-weight: inherit;
		font-size: inherit;
		line-height: inherit;
		margin: 0; } }

ul,ol {
	margin: 30px 0;

	li {
		position: relative;
		padding-left: 24px;
		&:first-child {
			margin-top: 0; }
		&:last-child {
			margin-bottom: 0; } }

	&+img {
		margin: round($baseGutter*0.2) 0 0; } }

ol {
	list-style-type: none;
	counter-reset: item;
	li {
		&:after {
			content: counters(item, ".")" ";
			counter-increment: item;
			font-size: inherit;
			line-height: 1;
			color: $blue;
			position: absolute;
			left: 15px;
			top: 4px;
			font-weight: 600; } } }

ul {
	li {
		&:after {
			content: '';
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: $baseTxt;
			position: absolute;
			left: 9px;
			top: 10px; } } }

.table-wrap {
	overflow-x: auto;
	width: 100%;
	margin: round($baseGutter*1.3) 0; }

table {
	min-width: 500px;
	width: 100%;
	th {
		padding: 12px 25px;
		font-size: rem(18px);
		line-height: rem(28.8px);
		font-weight: 600;
		text-align: left;
		border-bottom: 2px solid $baseTxt;
		position: relative;
		&:first-child {
			padding-left: 0;
			padding-right: 10px; }
		&:after {
			content: '';
			@include center('v');
			right: 0;
			width: 2px;
			height: 20px;
			background-color: $baseTxt; }
		&:last-child {
			&:after {
				display: none; } } }


	td {
		padding: 12px 25px;
		font-size: rem(15px);
		line-height: rem(21px);
		position: relative;
		&:first-child {
			padding-left: 0;
			min-width: 140px;
			padding-right: 10px; }
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			width: 2px;
			height: 100%;
			background-color: $gray2; }
		&:last-child {
			&:after {
				display: none; } } }

	tr {
		&:first-child {
			td {
				padding-top: 40px;
				&:after {
					height: 80%; } } } } }

h1,
h2,
h3,
h4,
h5,
h6 {
	&+p {
		margin-top: 0; } }

ul,ol,blockquote {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin: 0; } }

@include bp($bp-large) {
	h1 {
		font-size: $h1-fs-1280;
		line-height: $h1-lh-1280; }

	h2 {
		font-size: $h2-fs-1280;
		line-height: $h2-lh-1280; }

	h3 {
		font-size: $h3-fs-1280;
		line-height: $h3-lh-1280; }

	h4 {
		font-size: $h4-fs-1280;
		line-height: $h4-lh-1280; }

	h5 {
		font-size: $h5-fs-1280;
		line-height: $h5-lh-1280; }

	h6 {
		font-size: $h6-fs-1280;
		line-height: $h6-lh-1280; }

	p,
	ol li,
	ul li {
		font-size: $p-fs-1280;
		line-height: $p-lh-1280; }

	small {
		font-size: $small-fs-1280;
		line-height: $small-lh-1280; }

	blockquote {
		font-size: $blockquote-fs-1280;
		line-height: $blockquote-lh-1280; } }

@include bp($bp-tablet) {

	h1 {
		font-size: $h1-fs-768;
		line-height: $h1-lh-768;
		margin: $baseGutter 0;
		margin: 0 0 round($baseGutter*1); }

	h2 {
		font-size: $h2-fs-768;
		line-height: $h2-lh-768;
		margin: round($baseGutter*1) 0 round($baseGutter*1); }
	h3 {
		font-size: $h3-fs-768;
		line-height: $h3-lh-768;
		margin: round($baseGutter*1.5) 0 round($baseGutter*0.85); }

	h4 {
		font-size: $h4-fs-768;
		line-height: $h4-lh-768;
		margin: round($baseGutter*0.85) 0 round($baseGutter*0.7); }

	h5 {
		font-size: $h5-fs-768;
		line-height: $h5-lh-768;
		margin: round($baseGutter*1.4) 0 round($baseGutter*0.7); }

	h6 {
		font-size: $h6-fs-768;
		line-height: $h6-lh-768;
		margin: round($baseGutter*1.2) 0 round($baseGutter*0.6); }

	p,
	ol li,
	ul li {
		font-size: $p-fs-768;
		line-height: $p-lh-768; }

	small {
		font-size: $small-fs-768;
		line-height: $small-lh-768; }
	.table-wrap {
		margin: 20px 0; } }



@include bp($bp-small) {
	h1 {
		font-size: $h1-fs-480;
		line-height: $h1-lh-480;
		margin: 0 0 $baseGutter; }

	h2 {
		font-size: $h2-fs-480;
		line-height: $h2-lh-480;
		margin: $baseGutter 0 round($baseGutter*0.6); }

	h3 {
		font-size: $h3-fs-480;
		line-height: $h3-lh-480;
		margin: $baseGutter 0 round($baseGutter*0.6); }

	h4 {
		font-size: $h4-fs-480;
		line-height: $h4-lh-480;
		margin: $baseGutter 0 round($baseGutter*0.6); }

	h5 {
		font-size: $h5-fs-480;
		line-height: $h5-lh-480;
		margin: $baseGutter 0 round($baseGutter*0.6); }

	h6 {
		font-size: $h6-fs-480;
		line-height: $h6-lh-480;
		margin: $baseGutter 0 round($baseGutter*0.6); }

	p,
	ol li,
	ul li {
		font-size: $p-fs-480;
		line-height: $p-lh-480;
		margin: 12px 0;

		&+img {
			margin: round($baseGutter*0.2) 0 0; } }

	small {
		font-size: $small-fs-480;
		line-height: $small-lh-480; }

	blockquote {
		font-size: $small-fs;
		margin: 15px 0;
		padding: 15px 0;
		font-size: rem(15px);
		line-height: rem(21px);
		&:after {
			bottom: 4px; } }

	img {
		&+h1,
		&+h2,
		&+h3,
		&+h4,
		&+h5,
		&+h6,
		&+p,
		&+ul,
		&+ol {
			margin-top: round($baseGutter*0.7); }

		&+small {
			margin-top: 5px; } }

	ul, ol {
		margin: 25px 0; }

	table {
		td {
			font-size: rem(14px);
			padding: 6px 15px; }
		tr {
			&:first-child {
				td {
					padding-top: 27px; } } }
		th {
			padding: 6px 15px 12px;
			line-height: 1.1;
			min-width: 185px; } } }

