.close-icon {
	padding: 6px;
	width: 18px;
	height: 18px;
	color: $close-icon-color;
	cursor: pointer;
	transition: .3s linear;
	box-sizing: content-box;

	&:hover {
		color: $close-icon-color-hover; }

	svg {
		width: 100%;
		height: 100%; } }

.navigation-arrows {
	width: 100%;
	min-width: 88px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__item {
		padding: 6px;
		box-sizing: content-box;
		cursor: pointer;
		width: 18px;
		height: 18px;
		transform-origin: 50%;
		color: $nav-arrow-color;
		transition: .3s linear;

		&:hover {
			color: $nav-arrow-color-hover; }
		svg {
			width: 100%;
			height: 100%;
			fill: transparent; }

		&:first-child {
			transform: rotate(90deg);

			&:hover {
				transform: rotate(90deg) translateY(5px); } }

		&:last-child {
			transform: rotate(-90deg);

			&:hover {
				transform: rotate(-90deg) translateY(5px); } } } }
