.tooltipster-shadow-customized.tooltipster-shadow {

	.tooltipster-box {
		box-shadow: 0 2px 4px rgba(33, 36, 41, 0.05);
		background-color: transparent; }

	.tooltipster-content {
		text-align: center;
		background-color: $tooltip-bg;
		border-radius: $tooltip-bor-radius;
		padding: 13px 24px;
		color: $white;
		font-family: $baseFont;
		font-size: 14px;
		line-height: 140%; }

	.tooltipster-arrow {
		.tooltipster-arrow-border {
			border-top-color: $tooltip-bg; } } }
