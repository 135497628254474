.r-slider {
    padding: 50px 0 0;
    overflow-x: scroll;
    position: relative;
    @include bp(768px) {
        padding: 70px 0 0; }
    @include bp(1280px) {
        padding: 100px 0 0;
        overflow: visible;
        &::after {
            content: '';
            position: absolute;
            right: 0;
            width: 350px;
            height: 100%;
            top: 0;
            background: linear-gradient(to right, transparent, rgba(0,0,0,0.8));
            z-index: 1;
            pointer-events: none; } }

    .swiper-wrapper {
        display: flex;
        align-items: stretch;
        gap: 10px;
        margin-right: -20px;
        @include bp(768px) {
            margin-right: -34px;
            gap: 30px; }
        @include bp(1280px) {
            margin-right: 0;
            gap: 0; } }

    &__slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 140px;
        flex-shrink: 0;
        @include bp(768px) {
            width: 190px; }
        @include bp(1280px) {
            width: 320px; } }
    &__pic {
        margin-bottom: 20px;
        @include bp(768px) {
            margin-bottom: 30px; }
        @include bp(1280px) {
            margin-bottom: 50px; } }
    &__name {
        color: var(--ffffff, #FFF);
        text-align: center;
        font-family: Benzin-Bold;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 28.8px */;
        text-transform: uppercase;
        margin-bottom: 5px;
        @include bp(768px) {
            font-size: 18px;
            margin-bottom: 10px; }
        @include bp(1280px) {
            font-size: 24px;
            margin-bottom: 15px;
            padding: 0 25px; } }

    &__note {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */;
        letter-spacing: -0.48px;
        margin-bottom: 15px;
        opacity: 0.5;
        @include bp(768px) {
            font-size: 16px;
            margin-bottom: 23px; } }
    &__icon {
        width: 24px;
        height: 24px;
        margin-top: auto;
        @include bp(768px) {
            width: 32px;
            height: 32px; } }

    &__arr {
        @include bp(1280px) {
            position: absolute;
            top: 230px;
            right: 50px;
            width: 40px;
            height: 28px;
            background-position: center;
            background-image: url('../images/arr-right.svg');
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 2;
            cursor: pointer; } }

    &__pagination {
        @include bp(1280px) {
            position: absolute !important;
            bottom: -80px !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 18px;

            .swiper-pagination-bullet {
                width: 60px;
                height: 4px;
                opacity: 0.5;
                background-color: #fff;
                border-radius: 5px;
                transition: all .3s; }

            .swiper-pagination-bullet-active {
                opacity: 1;
                width: 160px;
                height: 6px; } } } }

.releases {
    padding: 80px 0 66px;
    margin-top: 30px;
    background-color: rgba(#161616, 0.7);
    @include bp(768px) {
        padding: 150px 0 180px;
        margin-top: 70px; }
    @include bp(1280px) {
        padding: 190px 0;
        margin-top: 90px; } }

