.steps {
    background: rgba(#161616, 0.7);
    margin-top: 30px;
    padding: 40px 0 60px;
    overflow: hidden;
    margin-bottom: 30px;
    @include bp(768px) {
        padding: 90px 0 140px; }
    @include bp(1280px) {
        padding: 150px 0 180px; }
    @include bp(1600px) {
        padding: 90px 0 130px;
        margin-bottom: 80px; }

    &__row {
        display: flex;
        flex-direction: column;
        gap: 40px;
        @include bp(768px) {
            gap: 120px; }
        @include bp(1600px) {
            gap: 167px;
            flex-direction: row;
            align-items: center; } }

    &__col {
        counter-reset: list 0;
        flex: 1; }

    &__item {
        list-style-type: none;
        color: rgba(255, 255, 255, 0.50);
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 19.5px */;
        letter-spacing: -0.45px;
        display: flex;
        max-width: 568px;
        margin: 0 auto;
        margin-bottom: 24px;
        @include bp(768px) {
            margin-bottom: 50px;
            font-size: 18px; }
        @include bp(1280px) {
            font-size: 24px;
            margin-bottom: 60px; }
        @include bp(1600px) {
            max-width: 100%; }
        &::before {
            counter-increment: list;
            content: counter(list, decimal-leading-zero);
            display: inline-block;
            width: 20px;
            flex-shrink: 0;
            margin-right: 12px;
            @include bp(768px) {
                margin-right: 40px; } }
        &:last-child {
            margin-bottom: 0; } } }
