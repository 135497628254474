.page-content {
    max-width: 1600px;
    padding: 0 20px 40px;
    margin: 0 auto;
    margin-top: 70px;
    @include bp(768px) {
        padding: 0 40px 40px;
        padding-bottom: 86px;
        margin-top: 120px; }
    @include bp(1280px) {
        padding-bottom: 170px;
        margin-top: 180px; }
    @include bp(1600px) {
        padding-bottom: 200px; }
    &__main {
        color: rgba(255, 255, 255, 0.50);
        text-align: center;
        font-family: Benzin-Semibold;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 19.8px */;
        letter-spacing: 0.9px;

        span {
            color: #fff; }

        @include bp(768px) {
            font-size: 32px;
            letter-spacing: 1.6px; }
        @include bp(1280px) {
            font-size: 54px;
            letter-spacing: 2.7px; } }

    &__sub {
        margin-top: 24px;
        color: var(--ffffff, rgba(255, 255, 255, 0.50));
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 20.8px */;
        letter-spacing: -0.48px;
        @include bp(768px) {
            margin-top: 60px;
            font-size: 20px;
            letter-spacing: -0.6px; }
        @include bp(1280px) {
            margin-top: 50px;
            font-size: 30px;
            letter-spacing: -0.9px; }
        @include bp(1600px) {
            margin-top: 120px; } } }

