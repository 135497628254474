.connect {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 40;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.03) 100%), linear-gradient(0deg, rgba(145, 0, 255, 0.20) 0%, rgba(145, 0, 255, 0.20) 100%), rgba(8, 8, 8, 0.80);
    backdrop-filter: blur(75px);

    transition: transform .5s, opacity .5s;

    transform: translateY(-100%);
    opacity: 0;

    &._opened {
        transform: translateY(0);
        opacity: 1; }

    @include bp(768px) {
        -webkit-mask-image: url('../images/contact-mask-s.svg');
        mask-image: url('../images/contact-mask-s.svg');
        -webkit-mask-repeat: no-repeat;
        mask-size: cover;
        mask-repeat: no-repeat;
        mask-position: center bottom; }

    @include bp(1280px) {
        -webkit-mask-image: url('../images/contact-mask-m.svg');
        mask-image: url('../images/contact-mask-m.svg'); }

    &__inner {
        position: relative;
        z-index: 3;

        min-height: 100dvh;

        display: flex;
        flex-direction: column;

        @include bp(768px) {
            min-height: 0; }

        @include bp(1280px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;

            padding: 65px 110px 80px 75px; }

        @include bp(1600px) {
            padding: 65px 144px 80px 130px; } }

    &__content {
        padding: 42px 25px 40px;

        @include bp(768px) {
            padding: 65px 63px 60px; }

        @include bp(1280px) {
            padding: 0;
            flex: 1; } }

    &__note {
        color: #FA005A;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 37px;
        @include bp(768px) {
            margin-bottom: 52px; }
        @include bp(1600px) {
            margin-bottom: 65px; }

        opacity: 0;
        transform: translateY(-20px);
        transition: all .3s;
        transition-delay: .3s;

        @at-root ._opened & {
            opacity: 1;
            transform: translateY(0); } }

    &__title {
        color:  #FFF;
        font-family: Benzin-ExtraBold;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 24.2px */;
        letter-spacing: 0.66px;
        text-transform: uppercase;
        margin-bottom: 30px;
        @include bp(768px) {
            font-size: 30px;
            margin-bottom: 40px;
            padding-right: 20px; }
        @include bp(1600px) {
            font-size: 38px;
            margin-bottom: 40px; }

        opacity: 0;
        transform: translateY(-20px);
        transition: all .3s;
        transition-delay: .4s;

        @at-root ._opened & {
            opacity: 1;
            transform: translateY(0); } }


    &__mail {
        color: #FFF;
        font-family: Benzin-ExtraBold;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 24.2px */;
        letter-spacing: 0.66px;
        text-transform: uppercase;
        text-decoration: underline;
        @include bp(768px) {
            font-size: 30px; }
        @include bp(1600px) {
            font-size: 38px; }

        opacity: 0;
        transform: translateY(-20px);
        transition: all .3s;
        transition-delay: .5s;

        @at-root ._opened & {
            opacity: 1;
            transform: translateY(0); } }

    &__closer {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 40px;
        height: 40px;
        background-size: 10px;
        z-index: 4;
        border-radius: 6px;
        background: linear-gradient(225deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.07) 100%);

        @include bp(768px) {
            width: 50px;
            height: 50px; }

        @include bp(1600px) {
            right: 30px;
            top: 30px; }

        opacity: 0;
        transform: translateY(-20px);
        transition: all .3s;
        transition-delay: .4s;

        @at-root ._opened & {
            opacity: 1;
            transform: translateY(0); }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-image: url('../images/popup-closer.svg');
            width: 10px;
            height: 10px;
            background-position: center;
            background-repeat: no-repeat; } }

    &__map {
        background: rgba(#ccc, 0.3);
        flex-grow: 1;
        margin: 0 25px 40px;
        border-radius: 15px;
        overflow: hidden;

        @include bp(768px) {
            flex-grow: 0;
            max-width: 635px;
            aspect-ratio: 635 / 324;
            margin: 0 63px 78px; }

        @include bp(1280px) {
            flex: 1;
            // margin: 65px 0 80px
            margin: 0;
            aspect-ratio: 540 / 300;
            max-width: 540px; }

        @include bp(1600px) {
            aspect-ratio: 900 / 440;
            max-width: 900px; }

        opacity: 0;
        transform: translateY(-20px);
        transition: all .3s;
        transition-delay: .3s;

        @at-root ._opened & {
            opacity: 1;
            transform: translateY(0); } } }






