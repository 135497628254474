// H1
$h1-fs: rem(90px);
$h1-lh: 100%;

// H1 1280
$h1-fs-1280: rem(60px);
$h1-lh-1280: 100%;

// H1 768
$h1-fs-768: rem(60px);
$h1-lh-768: 100%;

// H1 480
$h1-fs-480: rem(42px);
$h1-lh-480: 100%;



// H2
$h2-fs: rem(60px);
$h2-lh: 110%;

// H2 1280
$h2-fs-1280: rem(44px);
$h2-lh-1280: rem(47px);

// H2 768
$h2-fs-768: rem(44px);
$h2-lh-768: 110%;

// H2 480
$h2-fs-480: rem(36px);
$h2-lh-480: 110%;



// H3
$h3-fs: rem(30px);
$h3-lh: 120%;

// H3 1280
$h3-fs-1280: rem(26px);
$h3-lh-1280: 120%;

// H3 768
$h3-fs-768: rem(26px);
$h3-lh-768: 120%;

// H3 480
$h3-fs-480: rem(23px);
$h3-lh-480: 120%;



// H4
$h4-fs: rem(30px);
$h4-lh: 120%;

// H4 1280
$h4-fs-1280: rem(24px);
$h4-lh-1280: 120%;

// H4 768
$h4-fs-768: rem(24px);
$h4-lh-768: 120%;

// H4 480
$h4-fs-480: rem(20px);
$h4-lh-480: 120%;



// H5
$h5-fs: rem(24px);
$h5-lh: 130%;

// H5 1280
$h5-fs-1280: rem(20px);
$h5-lh-1280: 130%;

// H5 768
$h5-fs-768: rem(20px);
$h5-lh-768: 130%;

// H5 480
$h5-fs-480: rem(18px);
$h5-lh-480: 130%;



// H6
$h6-fs: rem(24px);
$h6-lh: 120%;

// H5 1280
$h6-fs-1280: rem(20px);
$h6-lh-1280: 120%;

// H6 768
$h6-fs-768: rem(20px);
$h6-lh-768: 120%;

// H6 480
$h6-fs-480: rem(17px);
$h6-lh-480: 120%;



// P
$p-fs: rem(18px);
$p-lh: 140%;

// P 1280
$p-fs-1280: rem(16px);
$p-lh-1280: 140%;

// P 768
$p-fs-768: rem(16px);
$p-lh-768: 140%;

// P 480
$p-fs-480: rem(15px);
$p-lh-480: 140%;



// SMALL
$small-fs: rem(15px);
$small-lh: 120%;

// SMALL 1280
$small-fs-1280: rem(14px);
$small-lh-1280: 120%;

// SMALL 768
$small-fs-768: rem(14px);
$small-lh-768: 120%;

// SMALL 480
$small-fs-480: rem(12px);
$small-lh-480: 120%;



// BLOCKQUOTE
$blockquote-fs: rem(24px);
$blockquote-lh: rem(33.6px);

// BLOCKQUOTE 1280
$blockquote-fs-1280: rem(21px);
$blockquote-lh-1280: rem(29px);



//Button
$btn-fs: rem(16px);



//Link
$link-color: $blue;
$link-color-hover: $blue-dark;
$link-fs: rem(14px);
$link-lh: rem(16px);
$list-item-color: $purple;
