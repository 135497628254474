@import '../../components/popup/popup';

// thanks
.thank-popup {
	&__inner {
		max-width: 450px;
		margin: 0 auto;
		border-radius: 30px;
		width: 100%;
		background-color: $white;
		padding: 40px 50px;
		text-align: center;
		z-index: 101;
		position: relative;
		background-color: $white; }
	&__title {
		margin-top: 0;
		margin-bottom: 20px; }

	@include bp($bp-small) {
		.popup__content {
			display: flex;
			align-items: center; }
		&__inner {
			width: 90%;
			padding: 30px 20px; } } }
