.counter {
	display: flex;
	border: 1px solid $gray3;
	height: 50px;
	user-select: none;
	background: #fff;

	&__item {
		height: 100%;
		width: 50px;
		position: relative;

		&:first-child {
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 10px;
				height: 2px;
				background-color: $baseTxt;
				border-radius: 5px; } }

		&:last-child {
			&:before,&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 10px;
				height: 2px;
				background-color: $baseTxt;
				border-radius: 5px; }

			&:after {
				transform: rotate(90deg); } }

		&:last-child,&:first-child {
			cursor: pointer; }



		&:nth-child(2) {
			width: 30px;
			flex-shrink: 0;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: rem(15px);
			font-weight: 700;
			color: $baseTxt; } }

	&__input {
		width: 100%;
		height: 100%;
		text-align: center; }

	@include bp($bp-small) {
		height: 40px;
		&__item {
			height: 40px; } } }
