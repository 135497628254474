.equip {
    padding-top: 127px;
    @include bp(768px) {
        padding-top: 150px; }
    @include bp(1280px) {
        padding-top: 220px; }
    @include bp(1600px) {
        padding-top: 260px; }

    &__title {
        position: relative;
        z-index: 2;
        text-align: center;
        font-family: Benzin-ExtraBold;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 100% /* 50px */;
        text-transform: uppercase;
        width: 267px;
        word-wrap: break-word;
        margin: 0 auto;

        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: #fff;
        -webkit-text-stroke-width: 2.00px;

        margin-bottom: 47px;

        &._left {
            text-align: left;
            display: inline-block;
            .decor {
                text-align: left; } }

        @include bp(768px) {
            margin-bottom: 74px;
            font-size: 84px;
            width: auto;
            -webkit-text-stroke-width: 3.00px; }
        @include bp(1280px) {
            margin-bottom: 120px;
            font-size: 130px; }
        @include bp(1600px) {
            font-size: 150px;
            margin-bottom: 100px;
            br {
                display: none; } }
        &::after {
            content: attr(data-content);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            filter: blur(2px);
            -webkit-text-stroke-width: 8.00px;
            -webkit-text-stroke-color: #008257;
            z-index: -1;
            animation: blinker 6s infinite;
            @at-root ._red & {
                -webkit-text-stroke-color: #FA005A; } }

        .decor {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            color: #008257;
            text-align: center;
            font-family: Benzin-ExtraBold;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit /* 50px */;
            text-transform: inherit;
            filter: blur(5px);
            z-index: -1;
            animation: blinker2 3s infinite;
            @at-root ._red & {
                color: #FA005A; }
            &::after {
                content: attr(data-content);
                opacity: 0.7;
                filter: blur(40px); }

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 390px;
                height: 180px;
                border-radius: 392px;
                opacity: 0.3;
                background: #008257;
                filter: blur(50px);
                z-index: -1;
                animation: blinker 6s infinite;
                @at-root ._red & {
                    background: #FA005A; }
                @include bp(768px) {
                    width: 840px;
                    height: 110px;
                    top: calc(50% + 40px);
                    border-radius: 849px;
                    opacity: 0.4;
                    filter: blur(77.69230651855469px); }
                @include bp(1280px) {
                    width: 1500px;
                    height: 140px;
                    border-radius: 1531px; } }
            span {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                filter: blur(1px);
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-color: #008257;
                -webkit-text-stroke-width: 3.00px;
                z-index: -1;
                @at-root ._red & {
                    -webkit-text-stroke-color: #FA005A; }
                &:nth-child(1) {
                    transform: translate(4px); } } } }

    &__section {
        margin-bottom: 50px;
        @include bp(768px) {
            margin-bottom: 60px; }
        @include bp(1280px) {
            margin-bottom: 90px; }
        &:last-child {
            margin-bottom: 0; } }

    &__note {
        color: var(--ffffff, #FFF);
        text-align: center;
        font-family: Benzin-Bold;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */;
        text-transform: uppercase;
        margin-bottom: 20px;
        @include bp(768px) {
            margin-bottom: 24px;
            font-size: 18px; }
        @include bp(1280px) {
            margin-bottom: 30px;
            font-size: 24px; } }

    &__row {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;

        @include bp(768px) {
            gap: 30px;
            margin-bottom: 30px; }

        @include bp(1280px) {
            gap: 50px;
            margin-bottom: 50px; }

        &:last-child {
            margin-bottom: 0; } }

    &__pic {
        flex: 1; } }
