.welcome {
    display: flex;
    align-items: center;
    will-change: transform;
    @include bp(768px) {
        height: 100vh; }
    * {
        will-change: transform; }
    &__track {
        padding: 160px 20px 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        background: rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(60px);
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-image: url('../images/welcome-bg.png');
            background-size: cover;
            background-position: center;
            z-index: -1;
            transition: opacity .5s;



            @include bp(768px); }

        &._redirect {
            &::after {
                opacity: 0; } }
        @include bp(768px) {
            width: auto;
            height: 100%;
            flex-direction: row;
            padding: 0;
            padding-left: 100px; }
        @include bp(1600px) {
            padding-left: 240px; } }

    &__cards {
        display: flex;
        flex-direction: column;
        gap: 120px;
        width: 100%;
        @include bp(768px) {
            width: auto;
            flex-direction: row;
            gap: 90px;
            padding-right: 30px; }
        @include bp(1600px) {
            padding-right: 0;
            gap: 180px; } }

    &__screen {

        @include bp(768px) {
            width: 100vw;
            height: 100%;
            flex-shrink: 0; }
        &-row {
            @include bp(768px) {
                display: flex;
                justify-content: space-between;
                margin: auto 0; } }
        &-inner {
            @include bp(768px) {
                margin: 0 auto;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 30px; }
            @include bp(1280px) {
                padding: 30px 70px; }
            @include bp(1600px) {
                padding: 30px 70px 50px 240px; } }

        &-title {
            color: rgba(255, 255, 255, 0.50);
            font-family: Benzin-Semibold;
            font-style: normal;
            font-weight: 400;
            line-height: 110% /* 77px */;
            letter-spacing: 1.2px;
            font-size: 24px;
            margin-bottom: 24px;
            margin-top: 154px;
            text-align: center;
            @include bp(768px) {
                letter-spacing: 3.5px;
                margin-top: 0;
                text-align: left;
                max-width: 1020px;
                font-size: 50px;
                margin-bottom: 56px; }
            span {
                &.white {
                    color: #fff;
                    white-space: nowrap; } }
            @include bp(768px) {
                font-size: 36px;
                max-width: 460px; }
            @include bp(1024px) {
                font-size: 50px;
                max-width: 1020px; }
            @include bp(1600px) {
                font-size: 70px;
                margin-bottom: 88px; } }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include bp(768px) {
                padding-top: 20px; } }

        &-nav {
            display: none;
            @include bp(768px) {
                display: block;
                text-align: right; }

            &-link {
                display: block;
                margin-bottom: 35px;
                color: var(--ffffff, #FFF);
                font-family: Montserrat;
                font-size: 15px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                text-transform: uppercase;
                &:last-child {
                    margin-bottom: 0; } } }

        &-soc {
            display: flex;
            gap: 24px;
            justify-content: center;
            margin-bottom: 50px;
            @include bp(768px) {
                justify-content: flex-end;
                margin-top: 30px;
                margin-bottom: 0; }
            @include bp(1280px) {
                margin-top: 0; }
            &-link {
                svg {
                    width: 46px;
                    height: 46px; } }

            &._no-offset {
                margin: 0; } }

        &-btn {
            display: flex;
            justify-content: center;
            margin-bottom: 145px;
            @include bp(768px) {
                opacity: 0;
                margin-bottom: 0;
                justify-content: flex-start; } }

        &-footer {
            display: flex;
            flex-direction: column;
            padding-bottom: 67px;
            @include bp(768px) {
                align-items: flex-end;
                padding-bottom: 0; } }


        &-note {
            color: var(--ffffff, #FFF);
            text-align: center;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150% /* 21px */;
            letter-spacing: -0.42px;
            margin-top: 10px;
            opacity: 0.9;
            &._dev {
                margin-top: 70px; }

            @include bp(768px) {
                text-align: right; } }

        &-logo {
            display: none;
            @include bp(768px) {
                width: 102px;
                height: 78px;
                display: inline-block;
                margin-left: auto; } } } }

.js-reveal {
    display: inline-block; }

.js-reveal-part {
    @include bp(768px) {
        display: inline-block;
        opacity: 0;
        transform: translateY(15px) translateX(10px); } }
