.about {
    position: relative;
    margin-bottom: 230px;
    @include bp(768px) {
        margin-bottom: 420px; }
    @include bp(1280px) {
        margin-bottom: 654px; }
    @include bp(1400px) {
        margin-bottom: 470px; }
    &__decor {
        position: absolute;
        opacity: 0;
        &._1 {
            width: 238px;
            bottom: -22px;
            right: -43px;
            @include bp(768px) {
                right: 9px;
                bottom: -41px;
                width: 400px; }
            @include bp(1280px) {
                right: 45px;
                bottom: -140px;
                width: 656px; }
            @include bp(1600px) {
                bottom: 161px;
                right: auto;
                left: 1050px;
                width: 656px; }
            @include bp(1920px) {
                right: 185px;
                left: auto; } }

        &._2 {
            width: 149px;
            bottom: -93px;
            left: 10px;
            @include bp(768px) {
                left: 22px;
                bottom: -195px;
                width: 257px; }
            @include bp(1280px) {
                width: 380px;
                bottom: -244px;
                left: 64px; }
            @include bp(1600px) {
                width: 419px;
                left: 496px;
                bottom: -158px; } }

        &._3 {
            width: 43px;
            bottom: -95px;
            right: 76px;
            @include bp(768px) {
                width: 76px;
                right: 162px;
                bottom: -150px; }
            @include bp(1280px) {
                right: 270px;
                bottom: -310px;
                width: 119px; }
            @include bp(1600px) {
                right: 36%;
                transform: translateX(50%);
                bottom: -40px; } }

        &._4 {
            bottom: -180px;
            width: 100px;
            left: 50%;
            transform: translateX(-50%);

            @include bp(768px) {
                width: 160px;
                bottom: -326px; }
            @include bp(1280px) {
                width: 256px;
                bottom: -543px; }
            @include bp(1600px) {
                bottom: -348px; } } } }

