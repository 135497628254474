.promotion {
    padding: 70px 0 0;
    @include bp(768px) {
        padding: 128px 0 0; }
    @include bp(1280px) {
        padding: 157px 0 0; }
    @include bp(1600px) {
        padding: 227px 0 0; }

    &._gray {
        background-color: rgba(#161616, 0.7); }

    &__cards {
        padding-top: 23px; } }

.promotion-card {
    margin-bottom: 70px;
    max-width: 285px;
    @include bp(768px) {
        max-width: 582px; }
    @include bp(1280px) {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:nth-child(odd) {
            flex-direction: row-reverse;
            margin-bottom: -60px; } }

    &:last-child {
        margin-bottom: 0; }
    &__content {
        margin-bottom: 25px;
        @include bp(768px) {
            margin-bottom: 40px; }
        @include bp(1280px) {
            margin-bottom: 0;
            flex: 1; } }
    &__title {
        margin-bottom: 15px;
        color: #FFF;
        font-family: Benzin-ExtraBold;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 110% /* 24.2px */;
        letter-spacing: 0.66px;
        text-transform: uppercase;
        @include bp(768px) {
            font-size: 30px;
            margin-bottom: 20px; }
        @include bp(1280px) {
            font-size: 38px;
            margin-bottom: 26px; } }
    &__text {
        color: rgba(255, 255, 255, 0.50);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130% /* 20.8px */;
        letter-spacing: -0.48px;
        @include bp(768px) {
            font-size: 20px; }
        @include bp(1280px) {
            font-size: 30px;
            max-width: 700px; } }

    &__pic {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 355px;
            height: 81px;
            bottom: 70px;
            border-radius: 355px;
            opacity: 0.4;
            background: #FA005A;
            filter: blur(78px);
            z-index: -1;

            @include bp(1280px) {
                left: 30px;
                transform: none; } }

        @include bp(1280px) {
            flex: 1; } } }

.p-cards {
    padding-bottom: 44px;
    margin-left: -10px;
    @include bp(768px) {
        padding-bottom: 96px;
        margin-left: 0; }
    @include bp(1280px) {
        padding-bottom: 130px; }
    @include bp(1600px) {
        padding-bottom: 210px;
        display: flex;
        flex-wrap: wrap;
        gap:  160px 110px; } }

.p-card {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    @include bp(768px) {
        margin-bottom: 50px; }
    @include bp(1280px) {
        margin-bottom: 80px; }
    @include bp(1600px) {
        margin-bottom: 0;
        width: calc(50% - 55px); }
    &:last-child {
        margin-bottom: 0; }
    &__pic {
        width: 100px;
        height: auto;
        margin-right: 10px;
        flex-shrink: 0;
        @include bp(768px) {
            width: 160px;
            height: 160px;
            margin-right: 30px; }
        @include bp(1280px) {
            width: 180px;
            height: 180px;
            margin-right: 40px; } }
    &__title {
        color: #FFF;
        font-family: Benzin-ExtraBold;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 24.2px */;
        letter-spacing: 0.66px;
        text-transform: uppercase;
        margin-bottom: 15px;
        margin-top: 20px;
        @include bp(768px) {
            margin-top: 30px;
            font-size: 30px;
            letter-spacing: 0.9px;
            margin-bottom: 20px; }
        @include bp(1280px) {
            margin-top: 35px;
            font-size: 38px;
            letter-spacing: 1.14px;
            margin-bottom: 26px; } }
    &__text {
        color: rgba(255, 255, 255, 0.50);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 20.8px */;
        letter-spacing: -0.48px;
        @include bp(768px) {
            font-size: 20px;
            letter-spacing: -0.6px; }
        @include bp(1280px) {
            font-size: 30px; } } }
