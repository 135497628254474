.footer {
    padding: 0 50px 60px;

    &._hidden {
        display: none; }

    &__row {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        @include bp(1280px) {
            flex-direction: row; }

        &._top {
            padding-top: 44px;
            margin-bottom: 50px;
            @include bp(768px) {
                padding-bottom: 60px;
                padding-top: 172px;
                margin-bottom: 60px;
                border-bottom: 1px solid rgba(#fff, 0.2); }
            @include bp(1280px) {
                margin-bottom: 30px;
                padding-top: 14px;
                padding-bottom: 40px; } } }

    &__mid {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);

        &._logo {
            display: none;
            @include bp(768px) {
                display: block;
                position: absolute;
                top: 34px; }
            @include bp(1280px) {
                top: 0; } }

        &._policy {
            position: static;
            transform: none;
            margin: 10px 0 50px;
            @include bp(768px) {
                margin: 5px 0 40px; }
            @include bp(1280px) {
                margin: 0; } } }

    &__link {
        color: #FFF;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */;
        letter-spacing: -0.42px;
        opacity: 0.9; }

    &__nav {
        display: none;
        @include bp(768px) {
            display: flex;
            gap: 60px; }
        @include bp(1280px) {
            gap: 20px; }
        @include bp(1600px) {
            gap: 60px; }

        &-link {
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-transform: uppercase; } }

    &__controls {
        @include bp(768px) {
            display: flex;
            gap: 60px;
            align-items: center;
            margin-top: 50px; }
        @include bp(1280px) {
            margin-top: 0; }
        @include bp(1600px) {
            gap: 80px; } }

    &__btn {
        display: none;
        @include bp(768px) {
            display: block; } } }
