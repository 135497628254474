@font-face {
  font-family: Benzin-ExtraBold;
  src: url('../fonts/Benzin-ExtraBold.woff2') format("woff2"), url('../fonts/Benzin-ExtraBold.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Benzin-Bold;
  src: url('../fonts/Benzin-Bold.woff2') format("woff2"), url('../fonts/Benzin-Bold.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Benzin-Semibold;
  src: url('../fonts/Benzin-SemiBold.woff2') format("woff2"), url('../fonts/Benzin-SemiBold.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Regular.woff2') format("woff2"), url('../fonts/Montserrat-Regular.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Medium.woff2') format("woff2"), url('../fonts/Montserrat-Medium.woff') format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Black.woff2') format("woff2"), url('../fonts/Montserrat-Black.woff') format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Marck Script;
  src: url('../fonts/MarckScript.woff2') format("woff2"), url('../fonts/MarckScript.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Alt;
  src: url('../fonts/alt.woff2') format("woff2"), url('../fonts/alt.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }


