.form-checkbox {
	position: relative;
	padding: 0 0 0 32px;
	user-select: none;
	display: block;
	cursor: pointer;
	font-size: rem(16px);
	// line-height: 180%

	// &:not(.form-checkbox--color):hover
	// 	.form-checkbox__checkmark
	// 		border-color: #000

	&--yellow {
		.form-checkbox__text {
			color: $yellow; } }

	&[disabled] {
		opacity: .5;
		pointer-events: none; }

	&__input {
		position: absolute;
		cursor: pointer;
		opacity: 0;
		width: 0;
		height: 0;

		&:checked ~ .form-checkbox__checkmark {
			// background: $yellow
			border-color: $gray2;
			&:after {
				opacity: 1; } } }

	&__text {
		font-size: $checkbox-fs;
		color: $checkbox-color;
		line-height: 1.4; }

	&__checkmark {
		position: absolute;
		top: -2px;
		left: 0;
		border: 1px solid $gray3;
		background-color: $white;
		width: 24px;
		height: 24px;
		transition: .2s;

		&:after {
			background: url("../images/check.png") 50% no-repeat;
			background-size: 12px 10px;
			width: 100%;
			height: 100%;
			content: '';
			opacity: 0;
			transition: opacity 150ms ease-in-out;
			position: absolute;
			top: 0;
			left: 0; } }

	// color checker

	&--color {
		padding: 0;

		.form-checkbox {
			&__checkmark {
				position: relative;
				display: block;
				width: 30px;
				height: 30px;
				border-radius: 3px;
				border-color: transparent;
				&:after {
					display: none; }
				&:before {
					display: none;
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					bottom: 0;
					border: 4px solid #fff; } }

			&__input:checked ~ .form-checkbox__checkmark {
				border: 2px solid $yellow!important;
				&:before {
					display: block; } } } } }
