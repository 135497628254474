.btn {
	padding: 12px 20px;
	color: #fff;
	text-align: center;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	text-transform: uppercase;
	border-radius: 4px;
	background: #FA005A;
	display: inline-block;

	&._big {
		padding: 16px 22px;
		font-size: 15px; }

	@include bp(768px) {
		padding: 16px 22px;
		font-size: 15px; } }




