.header {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 20;
	opacity: 0;
	&.header-mobile {
		@include bp(1280px) {
			display: none; } }
	&__logo {
		position: relative;
		z-index: 3;
		width: 70px;
		@include bp(768px) {
			width: 80px; }
		@include bp(1280px) {
			width: 100px; } }
	&__inner {
		padding: 12px 15px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include bp(768px) {
			padding: 20px; }
		@include bp(1280px) {
			padding: 30px 26px; }
		@include bp(1600px) {
			padding: 30px; } }

	&__nav {
		@include bp(1280px) {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			gap: 36px; }
		@include bp(1600px) {
			gap: 60px; }

		&-link {
			@include bp(1279px, max) {
				position: relative;
				margin-bottom: 30px;
				display: block;
				color: #FFF;
				font-family: Benzin-ExtraBold;
				font-size: 30px;
				font-style: normal;
				font-weight: 400;
				line-height: 110%; /* 33px */;
				text-transform: uppercase;

				-webkit-text-fill-color: transparent;
				-webkit-text-stroke-color: #fff;
				-webkit-text-stroke-width: 2.00px;

				&::after {
					content: attr(data-content);
					position: absolute;
					left: 2px;
					top: 2px;
					bottom: -2px;
					right: -2px;
					z-index: -1;
					opacity: .5;
					-webkit-text-stroke-color: #FA005A;
					filter: blur(1.2971168756484985px);
					-webkit-text-stroke-width: 4.00px;
					opacity: 0.5; }

				.decor {
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					right: 0;
					opacity: 0.5;
					&::after {
						position: absolute;
						left: 5px;
						top: 8px;
						content: attr(data-content);
						-webkit-text-stroke-color: #FA005A;
						opacity: 0.7;
						filter: blur(21.618614196777344px);
						-webkit-text-stroke-width: 4.00px; }

					span {
						position: absolute;
						left: 3px;
						top: 3px;
						right: -3px;
						bottom: -3px;
						-webkit-text-stroke-color: #FA005A;
						-webkit-text-fill-color: transparent;
						z-index: -1;
						filter: blur(3.7162392139434814px); } }

				&._active {
					-webkit-text-fill-color: transparent;
					-webkit-text-fill-color: #fff;
					-webkit-text-stroke-color: transparent;
					&:after,
					.decor {
						opacity: 1; } }

				&:last-child {
					margin-bottom: 76px; }

				@include bp(768px) {
					max-width: 500px;
					font-size: 46.68px;
					margin-bottom: 43px;
					&:last-child {
						margin-bottom: 100px; } } }

			@include bp(1280px) {
				color: #FFF;
				font-family: Montserrat;
				font-size: 15px;
				font-style: normal;
				font-weight: 900;
				line-height: normal;
				text-transform: uppercase;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					top: calc(100% + 5px);
					height: 1px;
					width: 0;
					background-color: #fff;
					transition: width .3s;
					z-index: 2; }

				&:hover,
				&._active {
					&::after {
						width: 100%; } }

				.decor {
					display: none; } } } }

	&__drop {
		@include bp(1279px, max) {
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			min-height: 100dvh;
			background: rgba(0, 0, 0, 0.5);
			backdrop-filter: blur(60px);
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 200px 33px;
			@include bp(768px) {
				padding: 300px 45px; }

			opacity: 0;
			visibility: hidden;
			transition: opacity .3s, visibility .3s;

			@at-root ._opened & {
				visibility: visible;
				opacity: 1; } } }

	&__opener {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 30px;
		width: 100px;
		height: 50px;
		background-size: cover;
		background-repeat: no-repeat;
		&._red {
			background-image: url('../images/menu-open-red.svg');
			&._opened {
				background-image: url('../images/menu-close-red.svg'); } }
		&._green {
			background-image: url('../images/menu-open-green.svg');
			&._opened {
				background-image: url('../images/menu-close-green.svg'); } }
		@include bp(768px) {
			right: 40px; }
		@include bp(1280px) {
			display: none; } } }
