.w-card {
    aspect-ratio: 720 / 640;
    flex-shrink: 0;
    position: relative;
    background-size: cover;
    background-position: center;
    opacity: 0;
    will-change: transform;
    transition: opacity .4s;

    @include bp(768px) {
        width: 500px;
        height: 440px; }

    @include bp(1600px) {
        width: 720px;
        height: 640px; }

    @at-root ._redirect & {
        &:not(._highlight) {
            opacity: 0 !important; } }

    &__link {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2; }

    &__pic {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        min-height: 100%;
        background-size: cover;
        background-position: top left;
        background-position: center;
        display: block;
        transition: background-position .5s, opacity .5s;
        aspect-ratio: 720 / 640;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            background: linear-gradient(to top, transparent, rgba(0,0,0,1));
            opacity: 0;
            transition: opacity .3s; }

        &._decor {
            z-index: 5;
            background-position: center;
            -webkit-mask-image: url('../images/mask.svg');
            mask-image: url('../images/mask.svg');
            -webkit-mask-repeat: no-repeat;
            mask-size: cover;
            mask-repeat: no-repeat;
            mask-position: center;
            min-height: 0;
            &::after {
                opacity: .95; }

            &::before {
                opacity: 1; } } }

    &__num {
        position: absolute;
        left: 15px;
        top: -15px;
        font-family: Benzin-ExtraBold;
        font-size: 80px;
        font-style: normal;
        font-weight: 400;
        line-height: 92%; /* 138px */;
        text-transform: uppercase;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: #fff;
        -webkit-text-stroke-width: 3.00px;
        @include bp(768px) {
            font-size: 90px;
            top: -25px; }
        @include bp(1600px) {
            font-size: 150px;
            top: -46px; }

        &::after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            content: attr(data-num);
            font-family: inherit;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit; /* 138px */;
            text-transform: inherit;
            -webkit-text-fill-color: inherit;
            -webkit-text-stroke-color: #FA005A;
            -webkit-text-stroke-width: 10.00px;
            opacity: 0.5;
            filter: blur(3px);
            z-index: -1; }

        .decor {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            font-family: Benzin-ExtraBold;
            font-size: inherit;
            font-style: normal;
            font-weight: 400;
            line-height: 92%; /* 138px */;
            text-transform: uppercase;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-color: #FA005A;
            -webkit-text-stroke-width: 3.00px;
            filter: blur(8.595000267028809px);
            &::before {
                content: '';
                width: 483px;
                height: 139px;
                position: absolute;
                left: 50%;
                top: calc(50% + 50px);
                transform: translate(-50%, -50%);
                background-color: yellow;
                border-radius: 483px;
                opacity: 0.4;
                background: #FA005A;
                filter: blur(60px); }
            span {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                font-family: Benzin-ExtraBold;
                font-size: inherit;
                font-style: normal;
                font-weight: 400;
                line-height: 92%; /* 138px */;
                text-transform: uppercase;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-color: #FA005A;
                -webkit-text-stroke-width: 3.00px;
                filter: blur(1.5px);
                z-index: 1; } } }

    &__name {
        position: absolute;
        left: 15px;
        bottom: -12px;
        color: var(--ffffff, #FFF);
        font-family: Benzin-ExtraBold;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 92%; /* 49.68px */;
        letter-spacing: 1.62px;
        text-transform: uppercase;
        @include bp(768px) {
            font-size: 40px;
            left: 30px;
            bottom: -19px; }
        @include bp(1600px) {
            left: 40px;
            bottom: -25px;
            font-size: 54px; } } }

