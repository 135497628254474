* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box; }

*::before,
*::after {
    box-sizing: inherit; }

html, :root {
    font-size: $baseRem;
    overflow-x: hidden;

    @include bp(1024px) {
        -webkit-text-size-adjust: 100%; } }

body {
    font-size: $baseRem;
    color: $baseTxt;
    min-width: 320px;
    padding: 0;
    margin: 0;
    background: #080808;
    overflow-x: hidden;

    ::-moz-selection {
        background: $gray4; }
    ::selection {
        background: $gray4; } }

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 2; }

.main {
    flex-grow: 1; }

img {
    max-width: 100%; }

.container {
    max-width: 1700px;
    padding: 0 20px;
    margin: 0 auto;
    @include bp(768px) {
        padding: 0 34px; }
    @include bp(1280px) {
        padding: 0 50px; }

    &._relative {
        position: relative;
        z-index: 1; } }

.contact {
      color: #FA005A;
      font-family: Benzin-ExtraBold;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 1.62px;
      text-transform: uppercase;
      margin: 60px 0;
      text-align: center;

      a {
            color: #fff; }

      @include bp(768px) {
            margin: 80px 0;
            font-size: 25px; }

      @include bp(1280px) {
            margin: 100px 0 80px;
            font-size: 40px; }
      @include bp(1600px) {
            font-size: 54px;
            margin: 150px 0 120px; } }


@keyframes blinker {
  0% {
        opacity: 0.2; }

  19% {
        opacity: 0.2; }

  20% {
        opacity: 0.3; }

  21% {
        opacity: 0.3; }

  22% {
        opacity: 0.2; }

  23% {
        opacity: 0.2; }

  36% {
        opacity: 0.2; }

  40% {
        opacity: 0.3; }

  41% {
        opacity: 0; }

  42% {
        opacity: 0.3; }

  43% {
        opacity: 0.15; }

  50% {
        opacity: 0.3; }

  100% {
        opacity: 0.3; } }

@keyframes blinker2 {
  0% {
        opacity: 1; }

  19% {
        opacity: 1; }

  20% {
        opacity: .1; }

  21% {
        opacity: 1; }

  22% {
        opacity: 1; }

  23% {
        opacity: 1; }

  36% {
        opacity: 1; }

  40% {
        opacity: 1; }

  41% {
        opacity: .1; }

  42% {
        opacity: 1; }

  43% {
        opacity: 1; }

  50% {
        opacity: 1; }

  51% {
        opacity: .1; }
  52% {
        opacity: 1; }

  100% {
        opacity: 1; } }

@keyframes blinker3 {
  0% {
        opacity: .5; }

  19% {
        opacity: .5; }

  20% {
        opacity: .1; }

  21% {
        opacity: .5; }

  22% {
        opacity: .5; }

  23% {
        opacity: .5; }

  36% {
        opacity: .5; }

  40% {
        opacity: .5; }

  41% {
        opacity: .1; }

  42% {
        opacity: .5; }

  43% {
        opacity: .5; }

  50% {
        opacity: .5; }

  51% {
        opacity: .1; }
  52% {
        opacity: .5; }

  100% {
        opacity: .5; } }
