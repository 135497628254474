//Buttons
$btn-blue: $blue;
$btn-blue-hover: $blue-dark;

$btn-red: #C4122F;
$btn-red-hover: #AD001C;

$btn-flat-color: $gray;
$btn-flat-bor: #ACB5BD;
$btn-flat-bor-hover: $gray;

$btn-border-radius: 8px;

//Button Group
$btn-group-bor: #ACB5BD;
$btn-group-bor-active: #495057;
$btn-group-bor-radius: 8px;
$btn-group-bor-radius-xs: 5px;
$btn-group-fs: 0.889rem;
$btn-group-color: #495057;
$btn-group-color-active: #212429;
$btn-h: 48px;
$btn-h-large: 60px;

//Input
$input-color: #495057;
$input-bor-color: #DDE2E5;
$input-bor-color-focus: $blue;
$input-color-error: #F03D3E;

$input-border-radius: 8px;
$input-border-radius-xs: 5px;
$input-fs: rem(16px);
$input-fs-xs: rem(14px);
$input-h: 48px;
$input-h-large: 60px;

//Select
$select-option-fs: 1.167rem;
$select-option-color-active: $blue;
$select-label-fs: 0.667rem;
$select-label-lh: 0.778rem;
$select-option-fs-xs: rem(16px);

//Checkbox
$checkbox-bor-width: 2px;
$checkbox-bor-radius: 4px;
$checkbox-bor-color: #DDE2E5;
$checkbox-fs: 0.889rem;
$checkbox-color: #495057;

//Radio-button
$radio-check-color: $blue;

//Tooltip
$tooltip-fs: 0.889rem;
$tooltip-lh: 1.056rem;
$tooltip-bg: $gray;
$tooltip-bor-radius: 10px;

//Tag
$tag-bor-color: #DDE2E5;
$tag-fs: 0.667rem;
$tag-lh: 0.778rem;
$tag-color: #495057;
$tag-icon-color: $blue;

//Filter
$filter-fs: 0.778rem;
$filter-bg: $gray;
$filter-item-fs: 0.667rem;
$filter-item-hover: #C4C4C4;

//Counter
$counter-fs: 1rem;
$counter-color: #495057;
$counter-border-color: #DDE2E5;
$counter-border-radius: 5px;
$counter-btn-color: #212429;

//Close Icon
$close-icon-color: #ACB5BD;
$close-icon-color-hover: #495057;

//Navigation Arrow
$nav-arrow-color: #495057;
$nav-arrow-color-hover: $blue;
