// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS
$baseTxt: #212121;
$black: #000;
$white: #fff;
$blue: #1FACD7;
$gray: #16171B;
$gray2: #9E9EA5;
$gray3: #E3E3E3;
$gray4: #F3F3F3;
$gray5: #F5F5F5;
$gray6: #F9FBFC;
$gray7: #AFAFAF;
$yellow: #F2E437;
$yellow2: #FFF35C;
$blue-dark: #034EA2;
$purple: #4263EB;


// FONTS
$baseFont: 'Gilroy';
$baseRem: 100%;

//shadows

//border-radius
$borderBase: 7px;
$borderMedium: 6px;
$borderLarge: 10px;

//transition
$transition: 0.4s ease;


// content width
$content-wrapper-width: 1720px;
$content-wrapper-width-small: 1290px;
$content-wrapper-gap: 50px;
$column-gap: 20px;


// Should use these
$bp-large: 1400px;
$bp-large2: 1100px;
$bp-medium: 992px;
$bp-tablet: 768px;
$bp-small2: 650px;
$bp-small: 576px;
$bp-extra-small: 480px;

//Small screen - netbooks
$pointNetbookStart: 1000px;
$pointNetbookEnd: 1400px;

// GUTTER
$baseGutter: 30px;

// TYPOGRAPHY
@import 'variables/typography-variables';

//UI ELEMENTS
@import 'variables/ui-elemnts-varibles';
