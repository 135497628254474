.preloader {
    position: fixed;
    left: 0;
    top: 0;
    height: 100dvh;
    width: 100dvw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    padding: 100px 20px;
    // animation: animate 4s ease-in-out forwards
    z-index: 30;
    will-change: transform;

    @include bp(768px, min) {
        padding: 114px; }

    &__image {
        max-width: 763px;
        width: 100%;
        animation: bg 5s ease-in-out forwards; }

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        animation: blur 5s ease-in-out forwards;
 } }        // animation: bg 5s ease-in-out forwards

@keyframes animate {
  0% {
    opacity: 1;
    visibility: visible; }
  95% {
    opacity: 1;
    visibility: visible; }
  100% {
    visibility: hidden;
    opacity: 0; } }

@keyframes bg {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; } }

@keyframes blur {
  0% {
    opacity: 0;
    filter: blur(0); }
  10% {
    opacity: 1; }
  60% {
    filter: blur(0); }
  90% {
    opacity: 1; }
  100% {
    filter: blur(30px); } }

