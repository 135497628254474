.form-select {
	position: relative;
	&__label {
		position: absolute;
		left: 20px;
		top: 8px;
		z-index: 20;
		font-weight: 500;
		font-size: $select-label-fs;
		line-height: $select-label-lh;
		color: $input-color; }

	&[disabled],
	&.disabled {
		opacity: .5;
		pointer-events: none; } }

.select2-container {
	* {
		outline: none!important; }
	.no-input {
		.select2-search {
			display: none; } }

	.input {
		.select2-search {
			font-size: $input-fs;
			color: $input-color;
			padding: 20px 20px 0;

			&__field {
				border-radius: $input-border-radius;
				border-color:  $input-bor-color;
				height: 48px;
				padding-left: 20px; } } }
	.select {
		height: $input-h-large;
		display: flex;
		align-items: center;

		&.select2-selection--single {
			border-radius: $input-border-radius;
			border: 1px solid $input-bor-color;

			@include bp($bp-small) {
				border-radius: $input-border-radius-xs; }


			.select2-selection__rendered {
				margin: 0px 0 0;
				padding-left: 20px;
				color: $input-color;
				font-size: $input-fs;
				overflow: visible;

				@include bp($bp-small) {
					font-size: $input-fs-xs; } }

			.select2-selection__arrow {
				top: 0;
				bottom: 0;
				right: 20px;
				margin: auto;
				height: 7px;
				width: 12px;
				b {
					width: 100%;
					height: 100%;
					background: url('../images/arrow.svg') 50% no-repeat;
					background-size: contain;
					border: unset;
					transition: .3s linear; } } }

		@include bp($bp-small) {
			height: $input-h; } }

	.select2-selection__placeholder {
		display: block;
		transform-origin: 0 0; }

	&--default {
		.select2-selection--single {
			.select2-selection__placeholder {
				color: $baseTxt; } } }

	&--open {
		.select {
			border-bottom-color: transparent!important;
			position: relative;

			// &:after
			// 	content: ''
			// 	position: absolute
			// 	left: 0
			// 	right: 0
			// 	bottom: 0
			// 	margin: 0 auto
			// 	width: calc(100% - 40px)
			// 	height: 1px
 }			// 	background-color: $input-bor-color

		.select2-dropdown {

			&--below {
				border-color: $input-bor-color;
				border-top-color: transparent;
				border-bottom-left-radius: $input-border-radius;
				border-bottom-right-radius: $input-border-radius; }

			&--above {
				border-color: $input-bor-color;
				border-bottom-color: transparent;
				border-top-left-radius: $input-border-radius;
				border-top-right-radius: $input-border-radius; } }

		.select2-selection--single {
			.select2-selection__arrow {
				b {
					transform: scaleY(-1);
					transform-origin: 50%; } } }

		&.select2-container--above {
			.select {
				border-top-color: transparent!important;
				border-bottom-color: $input-bor-color!important;

				&:after {
					bottom: unset;
					top: 0; } } } }

	.select2-results {
		.select2-results__options {
			padding: 22px 0;

			&::-webkit-scrollbar {
				width: 5px; }

			&::-webkit-scrollbar-track {
				background: $input-bor-color;
				border-radius: $input-border-radius; }

			&::-webkit-scrollbar-thumb {
				background: #888;
				border-radius: $input-border-radius; }

			&::-webkit-scrollbar-thumb:hover {
				background: #555; }

			.select2-results__option {
				margin: 22px 0!important;
				padding: 0 20px;
				font-size: $select-option-fs;
				background-color: transparent;
				color: $input-color;

				&:first-child {
					margin-top: 0!important; }

				&:last-child {
					margin-bottom: 0!important; }

				&:after {
					display: none; }

				&--highlighted {
					color: $yellow; }

				@include bp($bp-small) {
					font-size: $select-option-fs-xs; } } } } }


.js-select + .select2-container, .js-select-input + .select2-container {
	width: 100%!important; }

.select-bor-gray {
	.select2-container {
		.select {
			height: 50px;
			background-color: transparent;
			@include foil();
			background-size: 100% 100%;
			background-image: url('../images/select-mask.png'); }
		.select.select2-selection--single {
			border-radius: 0px;

			.select2-selection__rendered {
				font-size: rem(24px);
				font-weight: 500;
				line-height: 1;
				color: $gray3; } }

		@include bp($bp-tablet) {
			.select {
				height: 40px; }

			.select.select2-selection--single {
				.select2-selection__rendered {
					font-size: rem(16px); } }

			.select2-selection--single .select2-selection__rendered {
				padding: 0 12px; } }

		@include bp($bp-small) {
			.select {
				height: 38px; }

			.select.select2-selection--single {
				.select2-selection__rendered {
					font-size: rem(15px); } } } } }

.select-label {
	.select2-container {
		display: block;
		width: 100% !important;

		.select.select2-selection--single .select2-selection__arrow b {
			background: url(../images/arrow2.svg) 50% no-repeat; }

		.select {
			height: 50px;
			@include bp($bp-small) {
				height: 40px; } }
		.select.select2-selection--single {
			border-radius: 0;
			border-color: $gray3; }
		.select-label {
			font-size: rem(14px);
			color: $gray2;
			padding-right: 14px; }
		.select.select2-selection--single .select2-selection__rendered {
			font-size: rem(15px); }


		@include bp($bp-small2) {
			.select-label {
				display: none; }
			.select2-selection__rendered {
				padding-left: 14px !important; }
			.select.select2-selection--single .select2-selection__arrow {
				right: 12px; } } } }



