.top {
    position: relative;
    padding: 5px;
    @include bp(768px) {
        padding: 10px; }
    @include bp(1280px) {
        padding: 15px; }

    &._fake {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        border: 1px solid red;
        visibility: hidden;
        opacity: 0;
        pointer-events: none; }

    &__pic {
        position: relative;
        aspect-ratio: 380 / 465;
        background-size: cover;
        background-position: center;
        -webkit-mask-image: url('../images/mask320.svg');
        mask-image: url('../images/mask320.svg');
        -webkit-mask-repeat: no-repeat;
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        mask-position: center;
        width: 100%;
        max-height: 100svh;
        @include bp(768px) {
            -webkit-mask-image: url('../images/mask768.svg');
            mask-image: url('../images/mask768.svg');
            aspect-ratio: 748 / 655; }
        @include bp(1280px) {
            -webkit-mask-image: url('../images/mask1280.svg');
            mask-image: url('../images/mask1280.svg');
            aspect-ratio: 1250 / 885; }
        @include bp(1600px) {
            -webkit-mask-image: url('../images/mask.svg');
            mask-image: url('../images/mask.svg');
            aspect-ratio: 1890 / 885; }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            background: linear-gradient(to top, transparent, rgba(0,0,0,1));
            opacity: .95; } }

    &__title {
        position: absolute;
        z-index: 5;
        bottom: 84px;
        left: 20px;
        opacity: 0;
        &._default {
            left: 15px;
            bottom: 45px; }
        @include bp(768px) {
            left: 54px;
            bottom: 147px;
            &._default {
                left: 54px;
                bottom: 70px;
                max-width: 435px; }
            &._promotion {
                max-width: 100%; } }
        @include bp(1280px) {
            left: 75px;
            bottom: 148px;
            &._default {
                left: 75px;
                bottom: 148px;
                max-width: 780px; }
            &._promotion {
                max-width: 100%; } }
        @include bp(1600px) {
            left: 160px;
            &._default {
                left: 160px;
                bottom: 128px; }
            &._promotion {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);

                br {
                    display: none; } } }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            width: 365px;
            height: 153px;
            border-radius: 365.188px;
            opacity: 0.3;
            background: #FA005A;
            filter: blur(29.93346405029297px);

            animation: blinker 6s infinite;

            @at-root ._green & {
                background:#008257 {} }
            @include bp(768px) {
                width: 550px;
                height: 230px;
                filter: blur(45px); }
            @include bp(1280px) {
                width: 969px;
                height: 373px; } }
        &-line {
            display: block;
            position: relative;
            font-family: Benzin-ExtraBold;
            // font-family: Alt
            font-size: 50px;
            font-style: normal;
            font-weight: 900;
            line-height: 100% /* 50px */;
            text-transform: uppercase;
            max-width: 250px;
            letter-spacing: -2px;

            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-color: #fff;
            -webkit-text-stroke-width: 2.00px;

            &._promotion {
                max-width: 100%; }

            &._promotion {
                @include bp(1280px) {
                   max-width: 100%; } }

            &._manage {
                @include bp(1600px) {
                    br {
                        display: none; } } }

            @include bp(768px) {
                font-size: 84px;
                max-width: 100%;
                -webkit-text-stroke-width: 3px;
                max-width: 776px; }

            @include bp(1280px) {
                font-size: 150px; }

            &._offset {
                margin-left: 25px;
                @include bp(768px) {
                    margin-left: 50px; } }

            .decor {
                position: absolute;
                left: 0;
                top: 0;
                // right: 0
                bottom: 0;
                font-family: inherit;
                font-size: inherit;
                font-style: inherit;
                font-weight: inherit;
                line-height: inherit /* 138px */;
                text-transform: inherit;
                -webkit-text-fill-color: inherit;
                -webkit-text-stroke-color: #FA005A;
                -webkit-text-stroke-width: 10.00px;
                filter: blur(5.717291831970215px);
                opacity: 0.3;
                z-index: -1;
                animation: blinker 6s infinite;
                @at-root ._green & {
                    -webkit-text-stroke-color: #008257; } }

            &::after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                content: attr(data-text);
                font-family: inherit;
                font-size: inherit;
                font-style: inherit;
                font-weight: inherit;
                line-height: inherit /* 138px */;
                text-transform: inherit;
                -webkit-text-fill-color: inherit;
                -webkit-text-stroke-color: #FA005A;
                -webkit-text-stroke-width: 10.00px;
                opacity: 0.5;
                filter: blur(30px);
                z-index: -1;
                @at-root ._green & {
                    -webkit-text-stroke-color: #008257; } } }

        &._pink {} } }
