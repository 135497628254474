.round {
	position: relative;
	width: calc(100% - 20px);
	aspect-ratio: 1/1;
	max-width: 400px;
	margin: 0 auto;

	@include bp(768px) {
		max-width: 760px; }

	&__inner {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center; }

	&__center {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 80px;
		@include bp(768px) {
			width: 164px; }
		@include bp(1280px) {
			width: 174px; } }

	&__circle {
		border-radius: 50%;
		border: 1px solid rgba(255,255,255,0.1);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		aspect-ratio: 1/1;

		&._s {
			width: 42%; }

		&._m {
			width: 70%; }

		&._l {
			width: 95%; } }

	&__fly {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		animation: flyAnimation 20s linear infinite;

		offset-distance: 0%;
		offset-rotate: 0deg;


		@at-root ._chrome & {
			transform: translate(50%, 50%); } }

	&__fly._1 {
		offset-path: circle(17%);
		img {
			width: 70px;
			@include bp(768px) {
				width: 145px; }
			@include bp(1280px) {
				width: 154px; } } }

	&__fly._2 {
		offset-path: circle(35%);
		img {
			width: 46px;
			@include bp(768px) {
				width: 94px; }
			@include bp(1280px) {
				width: 100px; } } }

	&__fly._3 {
		offset-path: circle(35%);
		animation-delay: -5s;
		img {
			width: 70px;
			@include bp(768px) {
				width: 144px; }
			@include bp(1280px) {
				width: 152px; } } }

	&__fly._4 {
		offset-path: circle(35%);
		animation-delay: -10s;
		img {
			width: 50px;
			@include bp(768px) {
				width: 103px; }
			@include bp(1280px) {
				width: 109px; } } }

	&__fly._5 {
		offset-path: circle(35%);
		animation-delay: -15s;
		img {
			width: 56px;
			@include bp(768px) {
				width: 115px; }
			@include bp(1280px) {
				width: 122px; } } }

	&__fly._6 {
		offset-path: circle(48%);
		animation-delay: -18s;
		img {
			width: 50px;
			@include bp(768px) {
				width: 100px; }
			@include bp(1280px) {
				width: 105px; } } }

	&__fly._7 {
		offset-path: circle(48%);
		animation-delay:  -12s;
		img {
			width: 48px;
			@include bp(768px) {
				width: 95px; }
			@include bp(1280px) {
				width: 101px; } } }

	&__fly._8 {
		offset-path: circle(48%);
		animation-delay: -8s;
		img {
			width: 56px;
			@include bp(768px) {
				width: 118px; }
			@include bp(1280px) {
				width: 125px; } } }

	&__fly._9 {
		offset-path: circle(48%);
		animation-delay: -3s;
		img {
			width: 56px;
			@include bp(768px) {
				width: 102px; }
			@include bp(1280px) {
				width: 108px; } } } }


@keyframes flyAnimation {
	to {
		offset-distance: 100%; } }
