.service {
    padding-top: 70px;
    @include bp(768px) {
        padding-top: 150px; }
    @include bp(1280px) {
        padding-top: 200px; }
    &__title {
        color: #FFF;
        text-align: center;
        font-family: Benzin-ExtraBold;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 92% /* 23.92px */;
        letter-spacing: 0.78px;
        text-transform: uppercase;
        @include bp(768px) {
            font-size: 50px;
            letter-spacing: 1.5px; }
        @include bp(1280px) {
            font-size: 75px;
            letter-spacing: 2.25px; } }
    &__note {
        display: inline-block;
        left: 50%;
        top: -3px;
        position: relative;
        font-kerning: none;
        font-family: Marck Script;
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: 92% /* 40.48px */;
        letter-spacing: -1.76px;
        filter: blur(0.5px);
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: #FA005A;
        -webkit-text-stroke-width: 2.00px;
        &._center {
            transform: translateX(-50%); }
        @include bp(768px) {
            font-size: 70px;
            letter-spacing: -2.8px; }
        @include bp(1280px) {
            font-size: 100px;
            letter-spacing: -4px; }
        &::after,
        &::before {
            content: attr(data-content);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            animation: blinker 5s infinite; }

        &::after {
            opacity: 0.3;
            filter: blur(5px); }

        &::before {
            filter: blur(1px); }

        .decor {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 180px;
            height: 46px;
            background: #FA005A;
            opacity: 0.5;
            mix-blend-mode: lighten;
            filter: blur(30px);
            animation: blinker3 5s infinite;
            @include bp(768px) {
                width: 480px;
                height: 92px;
                filter: blur(55px); } }

        &._center {
            @include bp(1280px) {
                left: 58%; }
            .decor {
                width: 300px;
                height: 70px;
                border-radius: 299px;
                @include bp(768px) {
                    width: 543px;
                    height: 92px; }
                @include bp(1280px) {
                    width: 750px;
                    height: 108px; } } } }

    &__row {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-top: 30px;
        @include bp(1280px) {
            padding-top: 70px;
            gap: 50px;
            flex-direction: row;
            align-items: flex-start;
            perspective: 3000px; } } }

.service-card {
    $c: &;

    @include bp(1280px) {
        position: relative;
        aspect-ratio: 360 / 480;
        flex: 1;

        perspective: 3000px;

        &:hover {
            #{$c} {
                &__front {
                    transform: rotateY(180deg); } } } }

    &__media {
        @include bp(1280px) {
            position: relative;
            aspect-ratio: 360 / 480;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden; } }
    &__subname {
        display: none;
        @include bp(1280px) {
            display: block;
            position: absolute;
            width: 100%;
            bottom: -14px;
            left: 50%;
            transform: translateX(-50%);
            color: #FFF;
            text-align: center;
            font-family: Benzin-Bold;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 120% /* 28.8px */;
            text-transform: uppercase;
            padding: 0 10px; }
        @include bp(1600px) {
            bottom: -21px;
            font-size: 24px; } }
    &__front {
        @include bp(768px) {
            display: flex;
            align-items: center;
            flex-direction: row-reverse; }
        @include bp(1280px) {
            display: block;
            transition: transform 0.5s;
            transform-style: preserve-3d; } }
    &__content {
        background-size: 0px;
        @include bp(768px) {
            padding-left: 61px; }
        @include bp(1280px) {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transform: rotateY(180deg);
            background-size: cover;
            background-position: center; }
        @include bp(1600px) {
            padding: 30px; }

        &::after {
            @include bp(1280px) {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(to top, #F9005A, rgba(#F9005A, 0.4));
                z-index: -1; } }

        &::before {
            @include bp(1280px) {
                content: '';
                position: absolute;
                left: -30px;
                top: -15px;
                right: 0;
                bottom: -21px;
                z-index: 2;
                pointer-events: none;
                background-image: url('../images/service-decor-s.png');
                background-size: contain;
                background-repeat: no-repeat; }
            @include bp(1600px) {
                top: -27px;
                left: -34px;
                background-image: url('../images/service-decor.png'); } } }
    &__pic {
        aspect-ratio: 350 / 180;
        background-size: cover;
        background-position: center;
        margin-bottom: 20px;
        @include bp(768px) {
            aspect-ratio: 270 / 360;
            margin-left: 49px;
            flex-shrink: 0;
            width: 270px;
            margin-bottom: 0; }
        @include bp(1280px) {
            width: 100%;
            margin-left: 0; } }
    &__name {
        color:#FA005A {}
        font-family: Benzin-Bold;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120% /* 18px */;
        text-transform: uppercase;
        margin-bottom: 10px;
        @include bp(768px) {
            font-size: 18px; }
        @include bp(1280px) {
            font-size: 24px;
            margin-bottom: 13px;
            color: #fff; } }
    &__text {
        color: rgba(255, 255, 255, 0.50);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150% /* 21px */;
        letter-spacing: -0.42px;
        margin-bottom: 20px;
        @include bp(768px) {
            margin-bottom: 24px;
            font-size: 16px;
            letter-spacing: -0.48px; }
        @include bp(1280px) {
            color: #fff; } }
    &__link {
        color: #FFF;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase; } }
