.studio {
    position: relative;

    &__decor {
        position: absolute;
        opacity: 0;

        &._1 {
            width: 225px;
            right: 15px;
            bottom: 145px;
            @include bp(768px) {
                width: 410px;
                right: 30px;
                bottom: 200px; }
            @include bp(1280px) {
                width: 485px;
                top: 10%;
                bottom: auto;
                right: 80px; }
            @include bp(1600px) {
                width: 640px;
                right: 170px;
                bottom: 150px;
                top: auto; } } } }
