.popup {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	max-height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: -1;
	transition: opacity 0.3s linear;

	&--video {
		.popup__content {
			position: absolute;
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 100%;
			min-height: 100vh; }
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%; } }

	&__wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100%;
		position: relative;
		padding: 0;

		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(22, 23, 27, 0.5);
			z-index: 1;
			// transform: translateY(-100%)
 } }			// transition: 0.6s cubic-bezier(.8,.24,.2,.77)



	&__overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: all 0.3s linear;
		transition-delay: 0.3s;
		z-index: 2;
		opacity: 0; }

	&__inner {
		height: auto;
		color: $baseTxt;
		z-index: 5;
		opacity: 0;
		transform: scale(0.5);
		transform-origin: 50% 0%;
		transition: 0.5s;
		transition-delay: 0.4s;
		max-width: 580px;
		width: 100%;
		margin-left: auto;
		background-color: $white;
		transform: translateX(120%);
		position: relative;
		&:after {
			content: '';
			width: 100%;
			height: 11px;
			position: absolute;
			bottom: 0;
			left: 0;
			@include foil();
			background-image: url('../images/popup-decor.svg'); } }



	&__close {
		cursor: pointer;
		position: fixed;
		top: 12px;
		right: 24px;
		width: 32px;
		height: 32px;
		background: transparent;
		z-index: 5;
		transition: all 0.3s linear;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $yellow;
		transition: $transition;
		svg {
			fill: $baseTxt;
			width: 12px;
			height: 12px; }

		&:hover {
			background-color: $yellow2; } }


	&__content {
		width: 100%;
		position: relative;
		min-height: 100vh; }

	&__layout {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: all 0.3s linear;
		transition-delay: 0.3s;
		z-index: 2;
		opacity: 0; }

	&[data-block="video"] {
		.popup__inner {
			max-width: 100%; } }

	&[data-block="gallery-photos"] {
		.popup__content {
			min-height: 0; }
		.popup__inner {
			margin: 0 auto;
			background-color: transparent;
			max-width: 980px;
			&:after {
				display: none; }
			.swiper-slide {
				img {
					width: 100%; } } }
		@include bp($bp-small) {
			.popup__wrapper {
				align-items: center; } } }






	// active state
	&.is-show {
		opacity: 1;
		visibility: visible;
		z-index: 100;

		.popup__overlay {
			opacity: 0.1;
			transition-delay: 0s; }

		.popup__inner {
			// transition-delay: 0.3s
			opacity: 1; }

		.popup__inner {
			transform: none;
			opacity: 1; } }


	@include bp($bp-small) {
		&__inner {
			max-width: 100%;
			border-radius: 0; }

		&__wrapper {
			padding: 0;
			align-items: stretch; }


		&__content {
			min-height: 100vh; }

		&__close {
			transform: none;
			position: fixed; } } }



.modal-text {
	width: 100%;
	background-color: $white;
	border-radius: 50px;
	&__content {
		padding: 80px;
		position: relative;
		z-index: 3;

		@include bp($bp-tablet) {
			padding: 70px; } }

	@include bp($bp-small) {
		border-radius: 0;
		min-height: 100vh;
		&__content {
			padding: 60px 20px; } } }


.article {
	&__content {
		padding: 0px 60px 30px;
		&--large {
			padding-left: 40px;
			padding-right: 40px; }
		&--franshise {
			background-color: $yellow; } }
	&-date {
		color: $gray2;
		margin-bottom: 40px;
		margin-top: -9px; }
	&-slider {
		clip-path: polygon(0 0, 100% 0, 100% 90%, 94% 100%, 0 100%);
		position: relative; }
	&__title-form {
		margin: 15px 0 0; }
	&__image {
		margin-top: auto;
		margin-left: auto;
		text-align: right;
		height: calc(100vh - 215px);
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		img {
			max-height: 100%; }
		&--small {
			height: calc(100vh - 380px); } }
	&__content-thank {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		padding-bottom: 0;
		h3 {
			margin: 0px 0 20px;
			padding-top: 50px; } }
	&__nav {
		width: 100%;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 3;
		padding: 0 10px; }

	@include bp($bp-small) {
		&__content {
			padding: 0 20px 20px; }
		&-date {
			margin-bottom: 25px; }
		&__image {
			&--small {
				height: calc(100vh - 350px); } } } }

.article-franshise {
	background-color: $yellow;
	padding: 70px 0 40px;
	display: flex;
	flex-direction: column;
	min-height: 95vh;
	&__title {
		font-weight: 700;
		margin: 32px 0 22px; }
	&__list {
		margin: 20px 0; }
	&__item {
		margin: 0;
		padding: 29px 0;
		border-top: 2px solid $baseTxt;
		&:after {
			display: none; }
		&:last-child {
			border-bottom: 2px solid $baseTxt; } }
	&__link {
		display: block;
		position: relative;
		padding-right: 40px;
		font-size: rem(30px);
		line-height: 1;
		font-weight: 700;
		color: $baseTxt;
		svg {
			width: 32px;
			height: 24px;
			position: absolute;
			top: 50%;
			margin-top: -12px;
			right: 0; }
		&:hover {
			color: $baseTxt;
			svg {
				animation: arrow 0.75s ease-in-out; } } }

	&__bottom {
		margin-top: auto;
		p {
			margin: 0; } }

	&__btn {
		margin-top: 20px; }

	@include bp($bp-small) {
		padding-top: 55px;
		padding-bottom: 25px;
		&__link {
			font-size: rem(20px); }
		&__item {
			padding: 20px 0; } } }





